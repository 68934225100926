<template>
  <GuestLayout>
    <div
      class="m-auto flex flex-col overflow-hidden border rounded-3xl p-10 backdrop-blur-md">
      <div class="grid grid-cols-2 gap-5">
        <div class="flex">
          <img
            src="../../assets/logo.png"
            class="m-auto w-52">
        </div>
        <form
          class="w-80 flex flex-col text-white"
          @submit.prevent="login">
          <BaseInput
            v-model="form.email"
            :error="form.errors.email"
            name="email"
            type="email"
            label="E-Mail"
            class="bg-transparent"
            autocomplete="email"
            hide-asterisk />
          <BaseInput
            v-model="form.password"
            :error="form.errors.password"
            name="password"
            type="password"
            label="Passwort"
            class="bg-transparent"
            autocomplete="current-password"
            hide-asterisk />
          <Checkbox v-model="form.remember" label="Eingeloggt bleiben" />
          <BaseButton
            label="Login"
            type="submit"
            class="mt-5" />
        </form>
      </div>
    </div>
  </GuestLayout>
</template>

<script setup lang="ts">
import { useForm } from '@inertiajs/vue3';

const form = useForm({
  email: undefined,
  password: undefined,
  remember: false
});
function login() {
  form.post(route('login.store')), {
    onError: () => form.reset('password')
  };
}
</script>
